import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

const environment = import.meta.env.APP_ENV; // Update the variable to VITE_APP_ENV

let ErrorBoundary;

if (environment !== 'local') {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)]
  });

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary();
} else {
  // Fallback ErrorBoundary for local development
  ErrorBoundary = ({ children }) => <>{children}</>;
}

export { ErrorBoundary };

