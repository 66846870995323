//This keeps the InputMask warning from appearing in the console and in Bugsnag.
const suppressedWarnings = [
    'You provided a `value` prop to a form field without an `onChange` handler.',
    'findDOMNode is deprecated and will be removed in the next major release.'
];

const originalConsoleError = console.error;

console.error = (...args) => {
    if (typeof args[0] === 'string' && suppressedWarnings.some(warning => args[0].includes(warning))) {
        return;
    }
    originalConsoleError(...args);
};
